.App-header {
    /* background-color: #12171c; */
    align-items: center;
    background-image: url('../assets/img/01.png'), linear-gradient(91deg,#21252900 45%,#292d3200 100%)!important;
    color: white;
    display: flex;
    flex-direction: row;
    font-size: calc(10px + 2vmin);
    justify-content: center;
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    overflow-x: hidden;

  }
  .img-banner{
    transition: all 0.5s;
    width: 100vh;
    scale: 1.5;
    /* filter: drop-shadow(2px 7px 12px #121418fa); */
    /* color: #121418fa; */
    padding-right: 2.5em;
    animation: latido linear 3s infinite;
    
  }
  .img-banner:hover{
    transition: all 0.8s;
    scale: 1.45;
  }
 .img-banner2{
  width: 88px;
  position: relative;
  bottom: 0;
 }
 .img-banner3{
  margin-top: 88px;
  position: relative;
  width: 60px;
 }
  @keyframes latido {
    0% { filter: drop-shadow(2px 7px 12px #12141800);}
    80% { filter: drop-shadow(2px 7px 12px #ffc107);}
  }
  
  .App-header h1, .App-header h2{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  }
  .App-header h2{
      font-size: .8em;
  }
.sticky-style{
  position: sticky;
  top: 20%;
  transition: all 1s;
}
.content-img-banner{
  height: 100vh;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-between;
}
.content-img-text{
  padding: 2em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.carousel-control-prev, .carousel-control-next{
  width: auto!important;
}
.content-img-text h1{
  transition: all .3s .3s ease-in-out;
  animation: h1Slider  1s alternate;
  width: 80%;
  color: #ffc107;
}
.content-img-text h2{
  transition: all .3s .3s ease-in-out;
  animation: h2Slider  0.5s alternate;
  width: 80%;
  line-height: 1.7;
}

.content-img-text button{
  transition: all .2s .2s ease-in-out;
  animation: buttonSlider  0.6s alternate;
  position: absolute;
  bottom: 30%;
  padding: 1em;
}
.content-img-text button:hover{
  background-color: #7f7f7f;
  color: #fff;
  /* border: 4px solid #FF9800; */
  filter: drop-shadow(8px 17px 19px rgba(0, 0, 0, 0.556));
  border-radius: 15px;
  transition: all .2s .2s ease-in-out;
  box-shadow: 0 0 14px #FFC107;
  margin-top: 1em;
}


@keyframes h1Slider  {
	0% {margin-top: -100px;}
	100% {margin-top: 0;}
}
@keyframes h2Slider  {
	0% {margin-top: -100px;}
	100% {margin-top: 0;}
}

@keyframes buttonSlider  {
	0% {margin-bottom: -100px;}
  60% {margin-bottom: 30px; }
	100% {margin-bottom: 0;}
}

.circles{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #212529;
  background-image: radial-gradient(#2c3239, #212529);
  z-index: -1;
  /* animation: circle 100s linear infinite both; */
   
}
.circles .circle{
  position: relative;
  width: 50%;
  height: 50%;
  animation: circle 50s linear infinite both;
  filter: blur(30px);
  /* box-shadow: 0 0 10px 50px #000000d1; */
}
.circle .circle1{
  top: 0%;
  left: 0%;
  
}
.circle .circle2{
  top: 0%;
  right: 0%;
   
}
.circle .circle3{
  bottom: 0%;
  left: 0%;
  
}
.circle .circle4{
  bottom: 0%;
  right: 0%;
   
}

@keyframes circle{
	from {transform: rotate(0deg) scale(3)}
	to{transform: rotate(360deg) scale(3)}
}

  @media (max-width: 575.98px) {
    .App-header {
      display: flex;
      flex-direction: column-reverse;
      min-height: 75vh;
      background-image: url('../assets/img/01.png'), linear-gradient(91deg,#212529 45%,#292d32 100%)!important;
      }
      .content-img-banner {
          /* height: auto;
          padding: 2em; */
          display: none;
      }
      .content-img-text {
          padding: 2em 1em 0em;
      }
      .content-img-text h1{
        font-size: 1.6em;
      }
      .content-img-text h2{
        width: 80%;
      }
  }
  @media (max-width: 375px) {
    .content-img-text {
      padding: 3em 1em 0em;
    }
    .content-img-text h1{
      width: 100%;
      font-size: 1.5em;
    }
    .content-img-text h2{
      width: 100%;
      font-size: 0.7em;
    }
    .content-img-banner {
      display: none;
    }
    .text-pd {
      padding: 10px 0em;
    }
    .featurette{
      flex-direction: column-reverse
    }
  }
  @media (max-width: 360px) {
    
    
    .content-img-text h2{
      width: 100%;
      font-size: 1em;
    }
    .img-banner{
      transition: all 0.5s;
      width: 100%;
      scale: 0.9;
      filter: drop-shadow(2px 7px 12px #000000c4);
      color: #000000c4;
    }
    /* .content-img-banner {
        height: auto;
        padding: 1em;
    } */
    .content-img-text{
      padding: 0 1em 1em 1em ;
    }
  }
