body{
  background-color: #212529!important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.img-slider{
  padding: 3rem !important;
}
/* .img-banner {
  animation: App-logo-spin infinite 20s linear;
}
@media (prefers-reduced-motion: no-preference) {
  .img-banner {
    animation: App-logo-spin infinite 20s linear;
  }
} */

.App-header {
  /* background-color: #12171c; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title-scalar{
  color: #ffc107!important;
  font-size: 2rem;
}
.bg-scalar-base{
  background-color: #212529;
  /* background-image: linear-gradient(90deg,#212529 18%, #191c1e 20%,#212529 53%)!important; */
}
.img-width-home{
  width: 70%;
  transition: all 0.5s;
}
.img-width-home:hover{
  filter: drop-shadow(2px 7px 12px #ffc107);
  transition: all 0.8s;
}
.lead {
  font-size: 1rem!important;
}
.text-pd{
  padding: 15px 4em;
}
.align-self-center{
  align-self: center;
}
.featurette-divider{
  color: #ffc107;
}
.carousel-inner img{
  filter: contrast(0);
}
.ico-whatsapp-content{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #00c851!important;
  display: inline-flex;
  position: fixed;
  z-index: 1;
  bottom: 3em;
  right: 2em;
  box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
  justify-content: center;
  align-items: center;
}
    .ico-whatsapp-content a{
        z-index:1;
    }
    .ico-whatsapp-content svg {
        font-size: 30px;
        color: #ffffff;
    }
.carousel-control-prev, .carousel-control-next {
  width: auto;
}
.bg-card-scalar{
  background-color: #212529!important;
  border: 2px solid #626262!important;
  border-radius: 9px;
}
.bg-card-scalar img{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  padding: 1em;
  padding: 1.66em 1em;
}
.paket{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0px;
    padding: 1em;
    font-size: 1.65em;
  }
.casa-santivanez{
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0px;
  padding: 1em;
  font-size: 1.2em;
}

.presentation{
  text-align: justify;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ico-whatsapp-content::before, .ico-whatsapp-content::after {
  content:"";
  position:absolute;
  top: 50%;
  left: 50%;
  transform:translate(-50%, -50%);
  width: 50px;
  height: 50px;
  border: 10px solid rgba(128, 128, 128, 0);
  border-radius:100%;
  animation: latido-wahts linear 3s infinite;
}
.ico-whatsapp-content::after {
  animation-delay: -1.5s;
}
@keyframes latido-wahts {
  0% { width:70px; height:70px; border: 2.5px solid #00c851}
  80% {  width:120px; height:120px; border:10px solid transparent;  }
}

@media (max-width: 575.98px) {
  .img-slider {
    padding: 1rem !important;
  }
}

@media (max-width: 360px) {

}

@media (prefers-reduced-motion: reduce){
  .carousel-item {
      transition: transform .6s ease-in-out,-webkit-transform .6s ease-in-out!important;
  }
}

